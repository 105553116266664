import React from 'react'
import UnityComponent from './UnityComponent'
import Procesos from './procesos/Procesos'

const BimSection = () => {
  return (
    <main>
      <section class="section-bim" id="bim">
        <div class="bim">
          <div class="bim-text-box">
            <h1 class="heading-primary">
              Optimización intralogística y energética
            </h1>
            <p class="bim-description">
              Descubre cómo nuestra plataforma integra tecnologías BIM y Unity
              para revolucionar el diseño en la construcción. Simplifica
              procesos, explora las características de los sistemas y realiza
              cálculos intralogísticos y energéticos precisos en un entorno
              virtual colaborativo.
            </p>
          </div>
          <UnityComponent />
        </div>
      </section>
      <section class="section-developed">
        <div class="container">
          <h2 class="heading-developed-in">Desarrollado por</h2>
          <div class="logos">
            <img src={'/img/logos/atbim.png'} alt="Atbim logo" />
            <img
              src="img/logos/electroingenium.png"
              alt="Electroingenium logo"
            />
            <img src="img/logos/nabladot.png" alt="Nabladot logo" />
            <img src="img/logos/unizar.png" alt="Unizar logo" />
          </div>
        </div>
      </section>
      <Procesos />
    </main>
  )
}

export default BimSection
