import React, { useEffect, useRef } from 'react'

const UnityComponent = () => {
  const canvasRef = useRef(null)

  useEffect(() => {
    const config = {
      arguments: [],
      dataUrl: '/Version8_build_web.data',
      frameworkUrl: '/Version8_build_web.framework.js',
      codeUrl: '/Version8_build_web.wasm',
      streamingAssetsUrl: 'StreamingAssets',
      companyName: 'Electroingenium',
      productName: 'BIMLoggy',
      productVersion: '0.1',
    }

    window.createUnityInstance(canvasRef.current, config)

    return () => {
      // Cleanup code if needed
    }
  }, [])

  return (
    <div id="unity-container" className="unity-desktop">
      <canvas ref={canvasRef} id="unity-canvas" tabIndex={-1}></canvas>
    </div>
  )
}

export default UnityComponent
