function Procesos() {
  return (
    <section className="section-procesos" id="procesos">
      <div className="container">
        <span className="subheading">Procesos</span>
        <h2 className="heading-secondary">¿En qué consiste nuestro trabajo?</h2>
        <div className="procesos-list">
          <div className="proceso">
            <p className="step-number">01</p>
            <h3 className="heading-tertiary">
              Optimiza el flujo de materiales y recursos
            </h3>
            <p className="step-description">
              Nuestra integración de BIM y Unity no solo permite la creación de
              modelos digitales detallados de sistemas productivos, procesos,
              logística y flujo de materiales, sino que también valora
              exhaustivamente su rendimiento. Con funciones avanzadas como la
              detección automática de cuellos de botella, análisis de
              rendimiento, utilización de máquinas y recursos, así como el
              consumo energético, podemos identificar áreas de mejora con
              precisión sin precedentes en un entorno virtual.​
            </p>
          </div>
          <div className="proceso">
            <p className="step-number">02</p>
            <h3 className="heading-tertiary">Colaboración sin Fronteras</h3>
            <p className="step-description">
              Con nuestra plataforma, la colaboración entre equipos se vuelve
              más fluida y efectiva. Los modelos digitales creados pueden ser
              compartidos y visualizados fácilmente en entornos virtuales
              colaborativos, lo que facilita la comunicación y la toma de
              decisiones informadas. Ya sea que los equipos estén dispersos
              geográficamente o trabajando en el mismo lugar, nuestra solución
              garantiza que todos estén en la misma página y puedan contribuir a
              la optimización del proyecto mediante un laboratorio virtual.
            </p>
          </div>
          <div className="proceso">
            <p className="step-number">03</p>
            <h3 className="heading-tertiary">
              Resultados Tangibles y Sostenibles
            </h3>
            <p className="step-description">
              Al utilizar nuestra integración de BIM y Unity, los profesionales
              de la construcción pueden obtener resultados tangibles y
              sostenibles. La capacidad de simular y analizar diferentes
              escenarios permite tomar decisiones fundamentadas que no solo
              optimizan el rendimiento intralogístico y energético, sino que
              también reducen costos y minimizan el impacto ambiental. Desde la
              planificación inicial hasta la ejecución del proyecto, nuestra
              plataforma impulsa la creación de edificaciones más eficientes y
              respetuosas con el medio ambiente.​
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Procesos
