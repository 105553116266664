import './App.css'
import './general.css'
import BimSection from './components/BimSection'
import Header from './components/Header'

function App() {
  return (
    <>
      <Header />
      <BimSection />
    </>
  )
}

export default App
