import React from 'react'

const Header = () => {
  return (
    <header className="header">
      <a href="#">
        <img className="logo" alt="BIM Loggy logo" src="img/Logo_orange.png" />
      </a>
      <nav class="main-nav">
        <ul class="main-nav-list">
          <li>
            <a className="main-nav-link" href="#bim">
              Como funciona
            </a>
          </li>
          <li>
            <a className="main-nav-link" href="#procesos">
              Procesos
            </a>
          </li>
          <li>
            <a className="main-nav-link nav-cta" href="#">
              Prueba gratis
            </a>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Header
